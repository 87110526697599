import React, {useEffect} from 'react';
// @ts-ignore
import ReactPannellum, { getConfig } from "react-pannellum";
// @ts-ignore
import MEDICAL_04 from '../assets/img/MEDICAL_04.jpg';
import CAFETERIA_04 from '../assets/img/CAFETERIA_04.jpg';
import CO_WORKING_04 from '../assets/img/CO_WORKING_04.jpg';


const images: any = {
  1: CO_WORKING_04,
  2: MEDICAL_04,
  3: CAFETERIA_04
}
const FullView3D = (props: any) => {
  /*useEffect(() => {

    let i = props.match.params.id ? +props.match.params.id : 1;
    if(i < 1) {
      i = 1;
    }
    else if(i > 3) {
      i = 1;
    }

    const staticPath = `./pano${i}.xml`;

    let swipe = document.createElement('script');
    swipe.type = "text/javascript";
    swipe.innerHTML = `
     pano1 = new pano2vrPlayer("container");

      console.log(pano1);
  window.addEventListener("load", function () {
      console.log("load");
    pano1.readConfigUrlAsync("${staticPath}");
  });
    `;
    document.body.appendChild(swipe);

    return () => {
      swipe.remove();
    };
  }, []);*/

  let i = props.match.params.id ? +props.match.params.id : 1;
  if(i < 1) {
    i = 1;
  }
  else if(i > 3) {
    i = 1;
  }

  return (
    <ReactPannellum
      id="1"
      sceneId="firstScene"
      style={{
        width: "100vw",
        height: "100vh",
        background: "#000000"
      }}
      imageSource={images[i]}
      config={{
        autoLoad: true,
        minPitch: -40,
        maxPitch: 40,
        showControls: false,
        mouseZoom: false,
        keyboardZoom: false,
        showZoomCtrl: false,
        doubleClickZoom: false,
      }}
    />
  )
};

export default FullView3D;
